import styled from 'styled-components';
import ImgAppDownload from '@/assets/images/img_app_download.webp';
import appDownloadHandler from '@/utils/appDownloadHandler';

export default function AppDownloadCard() {
  return (
    <Container src={ImgAppDownload} onClick={() => appDownloadHandler()}>
      <DescBox>
        <TextBox>
          <h1>앱에서 첫 투표하면 포인트가 쏟아져요</h1>
          <p>지금 앱을 다운받고 더 많은 혜택을 누리세요!</p>
        </TextBox>
        <StyledButton>최대 +100P 받기</StyledButton>
      </DescBox>
      <ImageBlock />
    </Container>
  );
}

const ImageBlock = styled.div`
  width: 120px;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  background: #000;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-break: keep-all;

  h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }

  p {
    color: #5b5b5b;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
`;

const DescBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
`;

const Container = styled.section<{
  src: string;
}>`
  width: 100%;
  max-width: 600px;
  height: 163px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background-color: #eeb9cb;
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 1px 2px 16px 0px rgba(72, 79, 97, 0.1);
  cursor: pointer;
`;
