import TimeCountdown from './TimeCountdown';
import styled from 'styled-components';
import { VoteStatusType } from '@/types/VoteTypes';
import VoteStatusChip from './VoteStatusChip';
import MyVoteStatusChip from './MyVoteStatusChip';
import { isPast } from 'date-fns';

interface VoteCardProps {
  voteStatus: VoteStatusType;
  model: string;
  modelKR: string;
  thumbnail: string;
  endDate: Date;
  prosCons: 0 | 1 | 2;
}

const scheduledStatuses = ['DISPOSAL_VOTE_SCHEDULED'];
const activeCardStatuses = [
  'DISPOSAL_VOTE_SCHEDULED',
  'DISPOSAL_VOTE_PROGRESS',
  'PROPOSAL',
];
const votingStatuses = ['DISPOSAL_VOTE_PROGRESS', 'PROPOSAL'];

export default function VoteCard({
  voteStatus,
  model,
  modelKR,
  thumbnail,
  endDate,
  prosCons,
}: VoteCardProps) {
  const isScheduled = scheduledStatuses.includes(voteStatus);
  const isActiveCardStatus = activeCardStatuses.includes(voteStatus);
  const isVoting = votingStatuses.includes(voteStatus);
  const isEnd = isPast(endDate);

  return (
    <Container isActiveCardStatus={isActiveCardStatus} isVoting={isVoting}>
      <BetweenBox>
        <VoteStatusChip voteStatus={voteStatus} />
        {!isScheduled && (
          <MyVoteStatusChip
            myVoteStatus={isEnd && prosCons === 0 ? 'isEnd' : prosCons}
          />
        )}
      </BetweenBox>
      <BetweenBox>
        <ProductNameBox isActiveCardStatus={isActiveCardStatus}>
          <h1>{model}</h1>
          <h2>{modelKR}</h2>
        </ProductNameBox>
        <ProductThumbnail src={thumbnail} alt="product-thumbnail" />
      </BetweenBox>
      {!isEnd && <TimeCountdown endDate={endDate} isScheduled={isScheduled} />}
    </Container>
  );
}

const ProductThumbnail = styled.img`
  color: #1d1d1d;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  min-width: 64px;
  min-height: 64px;
  max-width: 85px;
  max-height: 85px;
`;

const ProductNameBox = styled.div<{ isActiveCardStatus?: boolean }>`
  color: ${(props) => (props.isActiveCardStatus ? '#1d1d1d' : '#8E8E8E')};
  font-family: Pretendard;
  line-height: normal;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  h1 {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const Container = styled.div<{
  isActiveCardStatus?: boolean;
  isVoting?: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 16px;
  border-radius: 10px;
  border: ${(props) => props.isVoting && '1px solid #ff8a22'};
  box-shadow: ${(props) =>
    !props.isActiveCardStatus && '1px 2px 16px 0px rgba(72, 79, 97, 0.1)'};
  background-color: ${(props) =>
    !props.isActiveCardStatus ? '#e8e8e8' : 'white'};
`;
