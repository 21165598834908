import styled from 'styled-components';

interface ProductNameBoxProps {
  isActiveCardStatus?: boolean;
  model: string;
  modelKR?: string;
  thumbnail: string;
}

export default function ProductNameBox({
  isActiveCardStatus,
  model,
  modelKR,
  thumbnail,
}: ProductNameBoxProps) {
  return (
    <BetweenBox>
      <NameBox isActiveCardStatus={isActiveCardStatus}>
        <h1>{model}</h1>
        <h2>{modelKR}</h2>
      </NameBox>
      <ProductThumbnail src={thumbnail} alt="product-thumbnail" />
    </BetweenBox>
  );
}

const ProductThumbnail = styled.img`
  color: #1d1d1d;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  min-width: 64px;
  min-height: 64px;
  max-width: 85px;
  max-height: 85px;
`;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const NameBox = styled.div<{ isActiveCardStatus?: boolean }>`
  color: ${(props) => (props.isActiveCardStatus ? '#1d1d1d' : '#8E8E8E')};
  font-family: Pretendard;
  line-height: normal;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  h1 {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
