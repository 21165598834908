import axios from '../apis/axios-instance';
import { VoteTypes, VoteUserTypes } from '../types/AppTypes';
import { ApiResult } from '../types/Home/Types';
import {
  CheckExistingUserVoteResponse,
  VoteDetailPriceType,
  VoteDetailSaleType,
  VoteItem,
} from '../types/VoteTypes';

const getVoteListFetcher = async (voteId: string | undefined) => {
  const { data } = await axios.get<ApiResult<VoteTypes[]>>(
    `/v2/api/treasurer/app/vote/list/${voteId}`,
  );
  return data.data;
};

const getVoteUserDataFetcher = async (voteId: string) => {
  const { data } = await axios.get(`/v2/api/treasurer/app/vote/user/${voteId}`);

  return data.data;
};

const postVoteUserFetcher = async ({
  voteId,
  vote,
}: {
  voteId: number;
  vote: number;
}) => {
  const { data } = await axios.post(`/v2/api/treasurer/app/vote/${voteId}`, {
    vote,
  });

  return data.result;
};

const checkExistingUserVoteFetchers = async (proposalId: number) => {
  try {
    const response = await axios.get<ApiResult<CheckExistingUserVoteResponse>>(
      `/v2/api/treasurer/sellProposal/vote/check/${proposalId}`,
    );
    return response.data.data;
  } catch (error) {
    console.error('Error in checkExistingUserVoteFetchers:', error);
  }
};

// TODO: deprecated
const getVoteItemDataFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/app/vote/data/${tradeItemId}`,
  );

  return data.data;
};

const getVoteItemByProposalIdFetcher = async (proposalId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/sellProposal/vote/data/${proposalId}`,
  );
  return data.data;
};

const getLiveProposalVoteFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/item/vote/liveProposal/${tradeItemId}`,
  );
  return data.data;
};

const getDisposalInProgressItemFetcher = async () => {
  const { data } = await axios.get(
    `/v2/api/treasurer/user/disposal/inProgress`,
  );
  return data.data;
};

// * new vote api
const getVoteList = async () => {
  const { data } = await axios.get<ApiResult<VoteItem[]>>(
    `/v2/api/treasurer/user/vote/list`,
  );
  return data.data;
};

const getVoteDetailSale = async (voteId: string) => {
  const { data } = await axios.get<ApiResult<VoteDetailSaleType[]>>(
    `/v2/api/treasurer/app/vote/list/${voteId}`,
  );
  return data.data;
};

const getVoteDetailPrice = async (voteId: string) => {
  const { data } = await axios.get<ApiResult<VoteDetailPriceType>>(
    `/v2/api/treasurer/sellProposal/proposal/${voteId}`,
  );
  return data.data;
};

interface SubmitVotePricePrams {
  proposalId: number;
  prosCons: 1 | 2;
}

const submitVotePrice = async (params: SubmitVotePricePrams) => {
  const response = await axios.post(
    `/v2/api/treasurer/sellProposal/vote`,
    params,
  );
  return response.data;
};

const getVoteSessionAuth = async (uuid: string) => {
  const response = await axios.get(
    `/v2/api/treasurer/sellProposal/auth/${uuid}`,
  );
  return response.data.data;
};

export {
  getVoteList,
  getVoteDetailSale,
  getVoteDetailPrice,
  submitVotePrice,
  getVoteListFetcher,
  getVoteUserDataFetcher,
  postVoteUserFetcher,
  getVoteItemDataFetcher,
  getVoteItemByProposalIdFetcher,
  getLiveProposalVoteFetcher,
  checkExistingUserVoteFetchers,
  getDisposalInProgressItemFetcher,
  getVoteSessionAuth,
};
