import {
  CalculatePriceRequestQuery,
  SinglePriceMarketRequest,
  MarketItemResponse,
  CURRENT_API_VERSION,
  AdminTotalPieceResponse,
  AdminTotalPieceRequest,
  BaseResponse,
  CalculatedPriceRequestParams,
  CalculatedOrderSummaryResponse,
  QuoteDateRequestQuery,
  QuoteDateResponse,
  // RelatedItemResponse,
} from '@treasurer/common';
import { AxiosError } from 'axios';
import axios from '../apis/axios-instance';
import { ApiResult } from '../types/Home/Types';
import {
  ItemMarketPriceTypes,
  ItemTypes,
  NotificationTypes,
  OrderSummaryResponseTypes,
  TransactionResponseTypes,
} from '../types/ItemTypes';

const getItemDetailFetcher = async (tradeItemId: string) => {
  const { data } = await axios.get<ApiResult<ItemTypes>>(
    `/api/treasurerWeb/items/detail/${tradeItemId}`,
  );
  return data.data;
};

const getNotiCntFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get<ApiResult<NotificationTypes[]>>(
    `/api/treasurerWeb/notification/${tradeItemId}/all`,
  );
  return data.data;
};

const getItemOnsaleListFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get<ApiResult<{ total: number }>>(
    `/v2/api/treasurer/market/${tradeItemId}/onsale`,
  );
  return data.data;
};

const getItemMarketPriceFetcher = async (ticker: string) => {
  const { data } = await axios.get<ApiResult<ItemMarketPriceTypes>>(
    `api/treasurerWeb/items/${ticker}/market/price`,
  );
  return data.data;
};

const getOrderSummaryFetcher = async (
  tradeItemId: number,
  param: CalculatePriceRequestQuery,
) => {
  const { orderType, enteredPrice, enteredQuantity } = param;
  let queryParam: number | undefined;
  if (orderType === 'BID') {
    queryParam = enteredPrice;
  } else {
    queryParam = enteredQuantity;
  }
  const { data } = await axios.get<ApiResult<OrderSummaryResponseTypes>>(
    `/v2/api/treasurer/app/item/singlePriceMarket/${tradeItemId}/calculatedOrderInfo?entered${
      orderType === 'BID' ? 'Price' : 'Quantity'
    }=${queryParam}&orderType=${orderType}`,
  );
  return data.data;
};

// TODO: new calculated order summary
const getPurchaseOrderSummaryFetcher = async (param: any) => {
  try {
    const { data } = await axios.post<any>(
      `/v2/api/treasurer/app/singlePrice/buy/order`,
      param,
    );
    return data.data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

// New purchase with points fetcher
const requestPurchaseFetcher = async (params: any) => {
  try {
    const { data } = await axios.post(
      `/v2/api/treasurer/app/item/singlePriceMarket/buy/withPoint`,
      params,
    );
    return data.data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

const requestRecruitmentPurchaseFetcher = async (params: any) => {
  try {
    const { data } = await axios.post(
      `/v2/api/treasurer/app/item/open/buy`,
      params,
    );
    return data.data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

// TODO: use existing order summary api for sell orders
const getCalculatedOrderSummaryFetcher = async ({
  pathParams,
  pathParams: { tradeItemId },
  queryParams,
  queryParams: { orderType, enteredPrice, enteredQuantity },
}: {
  pathParams: CalculatedPriceRequestParams;
  queryParams: CalculatePriceRequestQuery;
}) => {
  let transactionInputQuery = 'entered';

  if (orderType === 'BID') {
    transactionInputQuery += `Price=${enteredPrice}`;
  } else {
    transactionInputQuery += `Quantity=${enteredQuantity}`;
  }

  const { data } = await axios.get<CalculatedOrderSummaryResponse>(
    `/v2/api/treasurer/app/item/singlePriceMarket/${tradeItemId}/calculatedOrderSummary?${transactionInputQuery}&orderType=${orderType}`,
  );
  return data.data;
};

const useRequestBuyFetcher = async (param: SinglePriceMarketRequest) => {
  try {
    const { data } = await axios.post<ApiResult<TransactionResponseTypes>>(
      `/v2/api/treasurer/app/item/singlePriceMarket/buy`,
      param,
    );
    return data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

const useRequestSellFetcher = async (param: SinglePriceMarketRequest) => {
  try {
    const { data } = await axios.post<ApiResult<TransactionResponseTypes>>(
      `/v2/api/treasurer/app/item/singlePriceMarket/sell`,
      param,
    );
    return data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

const getMarketItemListFetcher = async () => {
  const { data } = await axios.get<MarketItemResponse>(`/v2/market`);
  return data.data;
};

const getAdminTotalPieceFetcher = async (tradeItemId: number) => {
  try {
    const { data } = await axios.get<AdminTotalPieceResponse>(
      `${CURRENT_API_VERSION}/api/treasurer/items/${tradeItemId}/remainings`,
    );
    return data.data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

const getRelatedItemFetcher = async (tradeItemId: number) => {
  try {
    const { data } = await axios.get(
      `${CURRENT_API_VERSION}/api/treasurer/items/related/${tradeItemId}`,
    );
    return data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

export type SellProbabilityData = {
  searchResults: { mallName: string; priceData: number[] }[];
  distribution: number[];
  initialIndex: number;
};

export type PlatformPriceResponse = BaseResponse<SellProbabilityData>;

const getPlatformPriceOfItemFetcher = async (tradeItemId: number) => {
  try {
    const { data } = await axios.get<PlatformPriceResponse>(
      `${CURRENT_API_VERSION}/api/treasurer/priceSearch/new?tradeItemId=${tradeItemId.toString()}`,
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

const getMetalPriceHistoryFetcher = async ({
  queryParams: { assetsType, startDt, endDt },
}: {
  queryParams: QuoteDateRequestQuery;
}) => {
  const { data } = await axios.get<QuoteDateResponse>(
    `/api/skp/tradeItem/quote/date?assetsType=${assetsType}&startDt=${startDt}&endDt=${endDt}`,
  );
  return data.assetsList;
};

type IndexPriceHistoryResponse = {
  result: string;
  assetsList?: {
    assetsType: string;
    assetsPriceList: {
      baseDate: string;
      unitPrice: string;
    }[];
  }[];
};

const getIndexPriceHistoryFetcher = async ({
  tradeItemId,
  startDt,
  endDt,
}: {
  tradeItemId: number;
  startDt: string;
  endDt: string;
}) => {
  const { data } = await axios.get<IndexPriceHistoryResponse>(
    `/v2/api/treasurer/item/graph/data/${tradeItemId}?startDt=${startDt}&endDt=${endDt}`,
  );
  return data.assetsList;
};

interface getRankerGoldResponse {
  userId: number;
  nickname: string;
  quantity: number;
}

const getRankerGold = async () => {
  try {
    const { data } = await axios.get<{ data: getRankerGoldResponse[] }>(
      `${CURRENT_API_VERSION}/api/treasurer/ranking/gold/ranker`,
    );
    return data.data;
  } catch (err: AxiosError | any) {
    throw err.response.data;
  }
};

export {
  getItemDetailFetcher,
  getNotiCntFetcher,
  getItemOnsaleListFetcher,
  getItemMarketPriceFetcher,
  getOrderSummaryFetcher,
  getPurchaseOrderSummaryFetcher,
  requestPurchaseFetcher,
  requestRecruitmentPurchaseFetcher,
  getCalculatedOrderSummaryFetcher,
  useRequestBuyFetcher,
  useRequestSellFetcher,
  getMarketItemListFetcher,
  getAdminTotalPieceFetcher,
  getRelatedItemFetcher,
  getPlatformPriceOfItemFetcher,
  getMetalPriceHistoryFetcher,
  getIndexPriceHistoryFetcher,
  getRankerGold,
};
