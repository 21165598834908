import styled from 'styled-components';
import { MyVoteStatusType } from '@/types/VoteTypes';
import { IconVoteGiveUp, IconVoteNo, IconVoteYes } from '@/assets/icons/vote';

interface MyVoteStatusChipProps {
  myVoteStatus: MyVoteStatusType;
}

export default function MyVoteStatusChip({
  myVoteStatus,
}: MyVoteStatusChipProps) {
  switch (myVoteStatus) {
    case 0:
      return (
        <NotVoteContainer>
          <Text>{'투표하기 >'}</Text>
          <Round>+3P</Round>
        </NotVoteContainer>
      );
    case 1:
      return (
        <Container>
          <IconVoteYes />
          <Text>찬성완료</Text>
        </Container>
      );
    case 2:
      return (
        <Container>
          <IconVoteNo />
          <Text color="#213E85">반대완료</Text>
        </Container>
      );
    default:
      return (
        <Container>
          <IconVoteGiveUp />
          <Text color="#AC6AFF">투표기권</Text>
        </Container>
      );
  }
}

const Container = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const Round = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: #ff8a22;
  padding: 5px 2px;
  color: #fff;
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
`;

const Text = styled.span<{ color?: string }>`
  color: ${(props) => props.color ?? '#ff8a22'};
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
`;

const NotVoteContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
