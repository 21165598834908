import { appId, rediectUrl } from '@/config/coinone';
import { useCreateCryptoMaster } from '@/Queries/useCreateCryptoMaster';
import { CreateCryptoMasterRequest } from '@treasurer/common';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default function CryptoPage() {
  const { mutate: requestCreateCryptoMaster, isLoading } =
    useCreateCryptoMaster();
  const onClick = () => {
    window.location.href = `https://coinone.co.kr/account/login/?app_id=${appId}&next=${rediectUrl}`;
  };

  const onClickCreateMaster = (params: CreateCryptoMasterRequest) => {
    requestCreateCryptoMaster(params);
  };
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '200px',
        }}
      >
        <p>crypto</p>
        <button onClick={onClick}>코인원 로그인하기</button>
        <button
          onClick={() => onClickCreateMaster({ exchangeName: 'coinone' })}
        >
          마스터 등록하기
        </button>
      </div>
      {/* <CryptoMasterList /> */}
    </Container>
  );
}
