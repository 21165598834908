import { IconClose } from '@/assets/icons/common';
import CustomModal from '@/Components/Modal/CustomModal';
import styled from 'styled-components';
import ImgFeeTable from '@/assets/images/img_fee_table.png';
interface FeeInfoModalProps {
  modalOpen: boolean;
  onRequestClose: () => void;
}

export default function FeeInfoModal({
  modalOpen,
  onRequestClose,
}: FeeInfoModalProps) {
  return (
    <CustomModal
      modalOpen={modalOpen}
      onRequestClose={onRequestClose}
      customWidth={'335px'}
    >
      <ImageBox src={ImgFeeTable}>
        <Close onClick={() => onRequestClose()} />
      </ImageBox>
    </CustomModal>
  );
}

const Close = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const ImageBox = styled.div<{ src: string }>`
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  width: auto;
  height: 932px;
  overflow-y: auto;
`;
