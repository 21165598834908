import { Helmet } from 'react-helmet';

const locales = {
  en: 'en_US',
  ko: 'ko_KR',
};

const ReactHelmet = ({ data }) => {
  const lang = locales[data.locale] || locales.en;
  const { title } = data;
  const { description } = data;
  const image = data.image !== undefined && `${data.image}`;
  const canonical = `https://www.treasurer.co.kr${data.canonical}`;
  const type = data.type === undefined ? 'website' : data.type;
  const width = data.image && (data.width || 1200);
  const height = data.image && (data.height || 630);
  const keywords = data.keywords ? data.keywords : undefined;

  return (
    <Helmet titleTemplate="%s">
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonical ? (
        <link rel="canonical" href={canonical} />
      ) : (
        'https://www.treasurer.co.kr/'
      )}
      {image ? (
        <link rel="image_src" href={image} />
      ) : (
        <link
          rel="image_src"
          href="'https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png'"
        />
      )}
      {image ? (
        <meta itemProp="image" content={image} />
      ) : (
        <meta
          itemProp="image"
          content="https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png"
        />
      )}

      <meta property="og:site_name" content="트레져러" />
      <meta property="og:title" content={title} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : (
        <meta
          property="og:description"
          content="취미로 시작하는 명품 재테크. 파텍필립, 롤렉스, 로마네 꽁티, 에르메스 등 소장 가치가 높은 고가의 물품을 1,000원 단위로 트레져러에서 구매해보세요."
        />
      )}
      {canonical ? (
        <meta property="og:url" content={canonical} />
      ) : (
        <meta property="og:url" content="https://www.treasurer.co.kr/" />
      )}
      <meta property="og:locale" content={locales[lang]} />
      <meta property="og:type" content={type} />
      {keywords ? (
        <meta name="keywords" content={keywords} />
      ) : (
        <meta
          name="keywords"
          content="와인투자, 시계투자, 금투자, 시계 시세, 롤렉스 시세, 로마네 콩티, 시세, 조각투자, 조각판매, 조각구매, 조각거래, 대체자산, 희귀자산, 희소가치, 희소성, 프리미엄, 명품조각거래"
        />
      )}
      {image ? (
        <meta property="og:image" content={image} />
      ) : (
        <meta
          property="og:image"
          content="https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png"
        />
      )}
      {width ? (
        <meta property="og:image:width" content={width} />
      ) : (
        <meta property="og:image:width" content="1200" />
      )}
      {height ? (
        <meta property="og:image:height" content={height} />
      ) : (
        <meta property="og:image:height" content="628" />
      )}

      {/* <meta property="fb:pages" content="트레져러" /> */}
      {/* <meta property="fb:pages" content="141402401294739" /> */}

      {/* change type of twitter if there is no image? */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : (
        <meta
          name="twitter:description"
          content="취미로 시작하는 명품 재테크. 파텍필립, 롤렉스, 로마네 꽁티, 에르메스 등 소장 가치가 높은 고가의 물품을 1,000원 단위로 트레져러에서 구매해보세요."
        />
      )}
      {image ? (
        <meta name="twitter:image" content={image} />
      ) : (
        <meta
          name="twitter:image"
          content="https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png"
        />
      )}
      {/* <meta name="twitter:site" content="@트레져러" /> */}
      {canonical ? (
        <link rel="alternate" href={data.canonical} hrefLang={lang} />
      ) : (
        <link
          rel="alternate"
          href="https://www.treasurer.co.kr/"
          hrefLang={lang}
        />
      )}
      <script type="application/ld+json">{`
      {
        "@context": "http://schema.org/",
        "@type":"조각상품",
        "name":"${title}",
        "url": "${data ? canonical : 'https://www.treasurer.co.kr'}",
        "description":"${description || '조각상품 설명'}",
        "logo": "${
          image ||
          'https://treasurer-source.s3.ap-northeast-2.amazonaws.com/logo.png'
        }",
        "sameAs":["https://treasurer.co.kr/"]
      }
    `}</script>
    </Helmet>
  );
};

export default ReactHelmet;
