import styled from 'styled-components';
import ImgEventBadge from '@/assets/images/event/img_event_badge.png';
import { Link } from 'react-router-dom';
import { postReservationBadge } from '@/fetcher/userFetchers';

const EVENT_JOIN_BUTTON_TEXT = '구매하고 뱃지 받기';

const tradeItemId = 100184;

function BadgeEventLanding() {
  const targetEventItem = {
    image: ImgEventBadge,
    url: `/item/${tradeItemId}`,
    button: EVENT_JOIN_BUTTON_TEXT,
  };

  return (
    <Container>
      <EventImageWrapper>
        <img src={targetEventItem.image} />
        <Link
          to={targetEventItem.url}
          onClick={() => postReservationBadge(tradeItemId)}
        >
          <EventJoinButton>{targetEventItem.button}</EventJoinButton>
        </Link>
      </EventImageWrapper>
    </Container>
  );
}

export default BadgeEventLanding;

const EventJoinButton = styled.button`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: white;
  background: #64d998;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 25px 0;
  text-align: center;
  width: 89%;
  max-width: 490px;
  cursor: pointer;
`;

const EventImageWrapper = styled.div`
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
