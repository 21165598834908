import styled from 'styled-components';
import { useMutation } from 'react-query';
import { submitVotePrice } from '@/fetcher/VoteFetchers';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import CustomModal from '@/Components/Modal/CustomModal';

interface PriceVoteButtonBoxProps {
  voteId: number;
}

export default function PriceVoteButtonBox({
  voteId,
}: PriceVoteButtonBoxProps) {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const controlErrorModal = () => {
    setIsOpenErrorModal((isOpenErrorModalPrev) => !isOpenErrorModalPrev);
  };

  const history = useHistory();

  const { mutate: submitVotePriceMutate } = useMutation(submitVotePrice, {
    onSuccess: () => {
      history.push(`/vote-price-result/${voteId}`);
    },
    onError: () => {
      controlErrorModal();
    },
  });

  const onClickVoteButton = (voteValue: 1 | 2) => {
    submitVotePriceMutate({ proposalId: voteId, prosCons: voteValue });
  };

  return (
    <>
      <ButtonContainer>
        <Button bgColor="#4880ED" onClick={() => onClickVoteButton(1)}>
          찬성
        </Button>
        <Button bgColor="#DE5255" onClick={() => onClickVoteButton(2)}>
          반대
        </Button>
      </ButtonContainer>

      <CustomModal
        modalOpen={isOpenErrorModal}
        onRequestClose={() => controlErrorModal()}
      >
        <ModalContainer>
          <ModalTitle>매각희망가 투표 오류</ModalTitle>
          <ModalText>
            매각희망가 투표가 정상적으로 처리되지 않았습니다. 잠시 후 다시
            시도해 주세요.
          </ModalText>
          <ButtonBox>
            <ModalButton onClick={() => controlErrorModal()}>확인</ModalButton>
            <ModalButton
              onClick={() => history.replace(`/vote`)}
              color="#213E85"
            >
              뒤로가기
            </ModalButton>
          </ButtonBox>
        </ModalContainer>
      </CustomModal>
    </>
  );
}

const ModalButton = styled.button<{ color?: string }>`
  width: 100%;
  background-color: ${(props) => props.color ?? '#ff8a22'};
  padding: 12px 0;
  border-radius: 4px;
  color: var(--white-w-1, #fff);
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 12px;
`;

const ModalText = styled.p`
  color: var(--balck-b-1, #1d1d1d);
  font-family: SUIT;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ModalTitle = styled.h1`
  color: var(--balck-b-1, #1d1d1d);
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Button = styled.button<{ bgColor: string }>`
  color: white;
  background-color: ${(props) => props.bgColor ?? '#F7F7F7'};
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  color: #fff;

  text-align: center;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;
