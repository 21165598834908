import assets from '@/config/assets';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as KakaoIcon } from '@/assets/images/kakaotalk.svg';
import { ReactComponent as NaverBlogIcon } from '@/assets/images/naver_blog.svg';
import { ReactComponent as InstaIcon } from '@/assets/images/instagramSvg.svg';
import { useMediaQuery } from '@mui/material';

interface FooterItemType {
  text: string;
  to?:
    | string
    | {
        pathname: string;
        state: { num: number };
      };
  href?: string;
}

interface FooterContentType {
  title: string;
  href?: string;
  to?: string;
  list: FooterItemType[];
}

const TREASURER_INFO = {
  name: '(주) 트레져러',
  CEO: '김경태',
  address: '서울 강남구 선릉로 90길 36, 3층 트레져러',
  businessNumber: '527-81-01503',
  mailOrderSalesBusinessNumber: '2021-서울강남-03837호',
};

export default function Footer() {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const inValidShowPaths = new Set([
    'contract',
    'event',
    'my',
    'vote',
    'vote-sale',
    'vote-price',
    'vote-price-result',
  ]);
  const bottomPathname = ['/wearetreasurer'];

  if (bottomPathname.includes(location.pathname)) return null;
  if (inValidShowPaths.has(location.pathname.split('/')[1])) return null;

  const contents = [
    {
      title: '트레져러',
      to: '/',
      list: [
        { text: '트레져러 소개', to: '/about' },
        { text: '공지사항', to: '/notice' },
        { text: '1:1 문의', href: 'http://pf.kakao.com/_gPRxjs' },
        { text: '블로그', href: 'https://notice.treasurer.co.kr/' },
        { text: '트레져러 글로벌', href: 'https://treazer.com/' },
      ],
    },
    {
      title: '투표/매각',
      to: '/piece',
      list: [
        { text: '매각현황', to: '/soldout' },
        { text: '투표', to: '/vote' },
      ],
    },
    {
      title: '마켓',
      to: '/market',
    },
    {
      title: '매입',
      to: '/sell',
      list: [
        { text: '매입문의', to: '/sell' },
        { text: '시계 시세 조회', to: '/watch' },
      ],
    },
    {
      title: '인재채용',
      href: 'https://notice.treasurer.co.kr/recruit',
    },
    {
      title: '서비스 이용약관',
      to: '/privacy',
      list: [
        {
          text: '서비스 이용약관',
          to: {
            pathname: `/privacy`,
            state: { num: 0 },
          },
        },
        {
          text: '수수료 정책',
          to: {
            pathname: `/privacy`,
            state: { num: 1 },
          },
        },
        {
          text: '공동구매 약관',
          to: {
            pathname: `/privacy`,
            state: { num: 2 },
          },
        },
        {
          text: '금 서비스 이용약관',
          to: {
            pathname: `/privacy`,
            state: { num: 3 },
          },
        },
        {
          text: '개인정보 처리방침',
          to: {
            pathname: `/privacy`,
            state: { num: 4 },
          },
        },
        {
          text: '개인정보수집 이용동의',
          to: {
            pathname: `/privacy`,
            state: { num: 5 },
          },
        },
        {
          text: '마케팅 정보 활용',
          to: {
            pathname: `/privacy`,
            state: { num: 6 },
          },
        },
      ],
    },
    {
      title: '고객센터',
      list: [
        { text: '전화: 1670-5842', href: 'tel:1670-5842' },
        {
          text: 'contact@treasurer.co.kr',
          href: 'mailto:contact@treasurer.co.kr',
        },
        {
          text: '카카오톡 1:1 문의',
          href: isMobile
            ? 'http://pf.kakao.com/_gPRxjs/chat'
            : 'http://pf.kakao.com/_gPRxjs',
        },
      ],
    },
  ] as FooterContentType[];

  const icons = [
    {
      icon: <StyledInstaIcon />,
      href: 'https://www.instagram.com/treasurer.co.kr',
    },
    {
      icon: <StyledKakaoIcon />,
      href: isMobile
        ? 'http://pf.kakao.com/_gPRxjs/chat'
        : 'http://pf.kakao.com/_gPRxjs',
    },
    {
      icon: <StyledNaverBlogIcon />,
      href: 'https://blog.treasurer.co.kr',
    },
  ];

  return (
    <>
      <Wrapper>
        <Container>
          <SectionBox>
            <img src={assets.logo.default} alt="logo" />
            <LinkWrapper>
              {contents.map((content) => {
                return (
                  <LinkBox key={content.href ?? content.to}>
                    {content.href ? (
                      <TitleAItem
                        href={content.href}
                        rel="noopener noreferrer"
                        target="_blank"
                        key={content.title}
                      >
                        {content.title}
                      </TitleAItem>
                    ) : (
                      <TitleLinkItem key={content.title} to={content.to || '/'}>
                        {content.title}
                      </TitleLinkItem>
                    )}
                    <ItemBox>
                      {content.list?.map((item, itemIndex) => {
                        if (item.to) {
                          return (
                            <LinkItem
                              key={`${item.to}-${itemIndex}`}
                              to={item.to}
                            >
                              {item.text}
                            </LinkItem>
                          );
                        } else if (item.href) {
                          return (
                            <AItem
                              key={item.href}
                              href={item.href}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {item.text}
                            </AItem>
                          );
                        } else {
                        }
                      })}
                    </ItemBox>
                  </LinkBox>
                );
              })}
            </LinkWrapper>
          </SectionBox>

          <SectionBox style={{ borderTop: '1px solid #cacaca' }}>
            <BetweenBox style={{ alignItems: 'flex-end' }} isMobile={isMobile}>
              <Box>
                <TextBox>
                  <p>{TREASURER_INFO.name}</p>
                  <p>대표 : {TREASURER_INFO.CEO}</p>
                </TextBox>
                <p>{TREASURER_INFO.address}</p>
                <TextBox>
                  <p>사업자 등록번호 : {TREASURER_INFO.businessNumber}</p>
                  <p>
                    통신판매업신고 :{' '}
                    {TREASURER_INFO.mailOrderSalesBusinessNumber}
                  </p>
                </TextBox>
              </Box>
              <Box>
                <TextBox
                  style={
                    !isMobile
                      ? { justifyContent: 'flex-end', gap: '32px' }
                      : { justifyContent: 'flex-start', gap: '32px' }
                  }
                >
                  {icons.map((icon) => {
                    return (
                      <AItem
                        key={icon.href}
                        href={icon.href}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {icon.icon}
                      </AItem>
                    );
                  })}
                </TextBox>
                <p>
                  Copyright&copy;{new Date().getFullYear()} Treasurer. All
                  rights reserved.
                </p>
              </Box>
            </BetweenBox>
          </SectionBox>
        </Container>
      </Wrapper>
    </>
  );
}

const StyledKakaoIcon = styled(KakaoIcon)`
  & path {
    fill: #a3a7b0;
  }
`;
const StyledNaverBlogIcon = styled(NaverBlogIcon)`
  & path {
    fill: #a3a7b0;
  }
`;

const StyledInstaIcon = styled(InstaIcon)`
  & path {
    fill: #a3a7b0;
  }
`;

const TextBox = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    color: #a3a7b0;
  }
`;

const BetweenBox = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    !props.isMobile ? 'space-between' : 'flex-start'};
  flex-wrap: wrap;
  gap: ${(props) => (!props.isMobile ? '12px' : '32px')};
  width: 100%;
`;

const TitleAItem = styled.a`
  color: #000;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

const TitleLinkItem = styled(Link)<{ to: string }>`
  color: #000;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

const AItem = styled.a`
  color: #474f60;
  font-family: SUIT;
  font-size: 14px;
  line-height: 22px;
`;

const LinkItem = styled(Link)`
  color: #474f60;
  font-family: SUIT;
  font-size: 14px;
  line-height: 22px;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LinkBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 64px;
  flex-wrap: wrap;
`;

const SectionBox = styled.section`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const Container = styled.div`
  width: 1036px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const Wrapper = styled.footer`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #484f61;
  padding-bottom: 90px;
  border-top: 1px solid rgba(163, 167, 176, 0.5);
`;
