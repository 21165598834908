import { VoteStatusType } from '@/types/VoteTypes';
import styled from 'styled-components';

export default function VoteStatusChip({
  voteStatus,
}: {
  voteStatus: VoteStatusType;
}) {
  switch (voteStatus) {
    case 'DISPOSAL_VOTE_SCHEDULED':
      return (
        <Chip border="1px solid #FF8A22" color="#FF8A22">
          투표예정
        </Chip>
      );
    case 'DISPOSAL_VOTE_PROGRESS':
      return (
        <Chip border="none" color="#FF8A22" bgColor="rgba(255, 138, 34, 0.20)">
          투표 중
        </Chip>
      );
    case 'DISPOSAL_VOTE_ENDED':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          투표종료
        </Chip>
      );
    case 'DISPOSAL_VOTE_ACCEPTED':
      return (
        <Chip border="none" color="#213E85" bgColor="rgba(33, 62, 133, 0.20)">
          투표종료-가결
        </Chip>
      );
    case 'DISPOSAL_VOTE_REJECTED':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          투표종료-부결
        </Chip>
      );
    case 'DISPOSAL_PROGRESS':
      return <Chip border="1px solid #213E85">매각진행</Chip>;
    case 'DISPOSAL_FINESSED':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          매각완료
        </Chip>
      );
    case 'PENDING_PROPOSAL':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          매각희망가 검토 중
        </Chip>
      );
    case 'PROPOSAL':
      return (
        <Chip border="none" color="#FF8A22" bgColor="rgba(255, 138, 34, 0.20)">
          매각희망가 투표 중
        </Chip>
      );
    case 'PROPOSAL_ACCEPTED':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          매각희망가-가결
        </Chip>
      );
    case 'PROPOSAL_REJECTED':
      return (
        <Chip border="none" color="#D2D2D2" bgColor="#8E8E8E">
          매각희망가-부결
        </Chip>
      );
  }
}

interface ChipStyleType {
  border?: string;
  color?: string;
  bgColor?: string;
}

const Chip = styled.span<ChipStyleType>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 4px;
  width: fit-content;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border: ${(props) => props.border ?? '1px solid'};
  color: ${(props) => props.color ?? 'black'};
  background-color: ${(props) => props.bgColor ?? 'white'};
`;
