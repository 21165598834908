import { IconInfo } from '@/assets/icons/common';
import { ProductProfileType } from '@/types/ItemTypes';
import { useState } from 'react';
import styled from 'styled-components';
import CustomModal from '@/Components/Modal/CustomModal';
import { useHistory } from 'react-router-dom';

interface VotePriceProductInfoProps {
  productProfile: ProductProfileType;
  hasNoMarketPrice: boolean;
  productId: number;
}

export default function VotePriceProductInfo({
  productProfile,
  hasNoMarketPrice,
  productId,
}: VotePriceProductInfoProps) {
  const history = useHistory();

  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const controlInfoModal = () => {
    setIsOpenInfo((isInfoOpenPrev) => !isInfoOpenPrev);
  };

  const handleClickProductInfo = (tradeItemId: number) => {
    history.push(`/item/${tradeItemId}`);
  };

  const treasurerSellPrice = Math.round(
    productProfile.appraisalPrice * productProfile.sellPriceRate,
  );
  const totalPieceCount = productProfile.totalPieceCount;
  const perOnePiecePrice = Math.round(treasurerSellPrice / totalPieceCount);

  return (
    <>
      <Container>
        <InfoBox onClick={() => handleClickProductInfo(productId)}>
          <ThumbnailImage src={productProfile.thumbnail} />
          <NameBox>
            <h1>{productProfile.brand}</h1>
            <h2>{productProfile.modelKR}</h2>
            <h3>{productProfile.ticker}</h3>
          </NameBox>
        </InfoBox>

        <PriceBox>
          <BetweenBox>
            <TextBox>
              <p>트레져러 즉시판매가</p>
              <IconInfo
                style={{ cursor: 'pointer' }}
                onClick={() => controlInfoModal()}
              />
            </TextBox>
            <MoneyText>
              {hasNoMarketPrice
                ? '단일가 거래 불가 상품'
                : `${treasurerSellPrice.toLocaleString()}원`}
            </MoneyText>
          </BetweenBox>
          <BetweenBox>
            <TextBox>
              <p>1조각</p>
            </TextBox>
            <MoneyText>{perOnePiecePrice.toLocaleString()}원</MoneyText>
          </BetweenBox>
        </PriceBox>
      </Container>

      <CustomModal
        modalOpen={isOpenInfo}
        onRequestClose={() => controlInfoModal()}
      >
        <ModalContainer>
          <ModalTitle>트레져러 즉시판매가란?</ModalTitle>
          <ModalText>트레져러 마켓에서 바로 판매 가능한 금액입니다.</ModalText>
          <ModalButton onClick={() => controlInfoModal()}>확인</ModalButton>
        </ModalContainer>
      </CustomModal>
    </>
  );
}

const ModalButton = styled.button`
  width: 100%;
  background-color: #ff8a22;
  padding: 12px 0;
  border-radius: 4px;
  color: var(--white-w-1, #fff);
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ModalText = styled.p`
  color: var(--balck-b-1, #1d1d1d);
  font-family: SUIT;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ModalTitle = styled.h1`
  color: var(--balck-b-1, #1d1d1d);
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MoneyText = styled.p`
  color: #000;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: #000;
    font-family: SUIT;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
`;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h1 {
    color: #000;
    font-family: SUIT;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    color: #000;
    font-family: SUIT;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  h3 {
    color: #a6a6a6;
    font-family: SUIT;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

const ThumbnailImage = styled.div<{ src: string }>`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-color: #fff;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
`;
