export default function appDownloadHandler() {
  const exeDeepLink = () => {
    window.location.href = 'treasurerapp://';
  };

  const checkInstallApp = () => {
    function clearTimers() {
      clearInterval(check);
      clearTimeout(timer);
    }

    function isHideWeb() {
      if (document.visibilityState === 'hidden' || document.hidden) {
        clearTimers();
      }
    }
    const check = setInterval(isHideWeb, 100);

    const ua = navigator.userAgent.toLowerCase();
    const device =
      ua.indexOf('android') > -1
        ? 'android'
        : ua.indexOf('iphone') > -1
        ? 'iphone'
        : 'web';

    const redirectStore = () => {
      window.location.href =
        device === 'android'
          ? 'https://play.google.com/store/apps/details?id=com.treasurer.app'
          : 'https://apps.apple.com/app/%ED%8A%B8%EB%A0%88%EC%A0%B8%EB%9F%AC-%EC%A3%BC%EC%8B%9D%EC%B2%98%EB%9F%BC-%EA%B1%B0%EB%9E%98%ED%95%98%EB%8A%94-%EC%88%98%EC%A7%91%ED%92%88-%EC%A1%B0%EA%B0%81%ED%88%AC%EC%9E%90-%ED%94%8C%EB%9E%AB%ED%8F%BC/id1610330304';
    };

    const delay = device === 'iphone' ? 0 : 1500;

    const timer = setTimeout(() => {
      redirectStore();
    }, delay);
  };

  const redirectApp = () => {
    exeDeepLink();
    checkInstallApp();
  };

  return redirectApp();
}
