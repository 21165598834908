import { getHomeBanners } from '@/fetcher/userQueries';
import { useMemo } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import AppLink from '@/Components/AppLink';
import appDownloadHandler from '@/utils/appDownloadHandler';
interface EventItem {
  id: number;
  image: string;
  url: string;
  // * 1: active
  active: number;
  createdAt: string;
  updatedAt: string;
  // detail Image
  content1: string;
  // detail button text
  content2?: string;
}

const EVENT_JOIN_BUTTON_TEXT = '이벤트 참여하기';

function DefaultEventLandingPage() {
  const { data: eventItems } = getHomeBanners('popup');

  const parsed = queryString.parse(location.search);
  const targetId = Number(parsed.id);

  const targetEventItem = useMemo(() => {
    return (
      eventItems &&
      eventItems.find((eventItem) => {
        return eventItem.id === targetId;
      })
    );
  }, [eventItems]);

  const isOnlyApp =
    targetEventItem && targetEventItem?.url?.slice(0, 1) !== '/';
  const isRN = (window as any).isRNWebView || false;
  return (
    <Container>
      {(targetEventItem?.url!.length ?? 0) > 0 && targetEventItem && (
        <EventImageWrapper>
          <img src={targetEventItem.content1} />
          {isOnlyApp && !isRN ? (
            <EventJoinButton onClick={() => appDownloadHandler()}>
              {targetEventItem.content2!.length < 1
                ? EVENT_JOIN_BUTTON_TEXT
                : targetEventItem.content2}
            </EventJoinButton>
          ) : (
            <AppLink
              to={targetEventItem.url}
              notItemPage={!targetEventItem.url!.startsWith('/item')}
            >
              <EventJoinButton>
                {targetEventItem?.content2?.length! < 1
                  ? EVENT_JOIN_BUTTON_TEXT
                  : targetEventItem.content2}
              </EventJoinButton>
            </AppLink>
          )}
        </EventImageWrapper>
      )}
    </Container>
  );
}

export default DefaultEventLandingPage;

const EventJoinButton = styled.button`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: white;
  background: #fd7103;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 25px 0;
  text-align: center;
  width: 89%;
  max-width: 490px;
  cursor: pointer;
`;

const EventImageWrapper = styled.div`
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
