import { IconInfo } from '@/assets/icons/common';
import { ProductProfileType, UserItemOwnershipType } from '@/types/ItemTypes';
import {
  calculatePercentageChange,
  numberWithPlusOrMinusSign,
  roundToTwoDecimalPlaces,
} from '@/utils/calculationUtils';
import { useState } from 'react';
import styled from 'styled-components';
import CustomModal from '@/Components/Modal/CustomModal';
import FeeInfoModal from './FeeInfoModal';
import { VoteItem } from '@/types/VoteTypes';

interface VotePriceProposalInfoProps {
  productProfile: ProductProfileType;
  hasNoMarketPrice: boolean;
  proposal: VoteItem;
  userItemOwnership: UserItemOwnershipType;
}

const itemCategories = [
  { category: 'bag', perFee: 8 },
  { category: 'watch', perFee: 5 },
  { category: 'wine', perFee: 7 },
];

export default function VotePriceProposalInfo({
  productProfile,
  hasNoMarketPrice,
  proposal,
  userItemOwnership,
}: VotePriceProposalInfoProps) {
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const controlInfoModal = () => {
    setIsOpenInfo((isInfoOpenPrev) => !isInfoOpenPrev);
  };

  const [isOpenFeeInfo, setIsOpenFeeInfo] = useState(false);
  const controlFeeInfoModal = () => {
    setIsOpenFeeInfo((isOpenFeeInfoPrev) => !isOpenFeeInfoPrev);
  };

  const currentProposedPrice =
    proposal.proposedPrice ??
    productProfile.proposedSellPrice ??
    productProfile.recruitmentAmount;
  const proposedPrice =
    proposal.proposedPrice ?? productProfile.proposedSellPrice;
  const treasurerSellPrice = Math.round(
    productProfile.appraisalPrice * productProfile.sellPriceRate,
  );

  const calculatedPercentageDifference = hasNoMarketPrice
    ? roundToTwoDecimalPlaces(
        ((proposedPrice - currentProposedPrice) / currentProposedPrice) * 100,
      )
    : roundToTwoDecimalPlaces(
        ((Number(proposedPrice) - treasurerSellPrice) / treasurerSellPrice) *
          100,
      );
  const calculatedPercentageDifferenceDisplay =
    calculatedPercentageDifference ?? '-';
  const totalPieceCount = productProfile.totalPieceCount;
  const proposedUnitPrice = Math.round(Number(proposedPrice) / totalPieceCount);

  const ownedAmount = userItemOwnership.amount;
  const expectedSalesProceeds = Math.round(
    proposedUnitPrice * userItemOwnership.quantity,
  );
  const externalPlatformBuyFeeRate =
    productProfile.externalPlatformFeeRate ?? 1.1;
  const proposedPriceWithPlatformFee = Math.round(
    Number(proposedPrice) * externalPlatformBuyFeeRate,
  );
  const settlementAmountPercent = calculatePercentageChange(
    ownedAmount,
    expectedSalesProceeds,
  );
  const itemPreFee = itemCategories.find(
    (itemCategory) => itemCategory.category === productProfile.category,
  )?.perFee;

  return (
    <>
      <Container>
        <BetweenBox>
          <Title>매각희망가</Title>
          <TextBox>
            <Text>
              {hasNoMarketPrice ? '즉시 판매가보다' : '상품 오픈 당시 가격보다'}
            </Text>
            <Text
              color={
                calculatedPercentageDifferenceDisplay < 0
                  ? '#213E85'
                  : '#FF8A22'
              }
            >
              {numberWithPlusOrMinusSign(calculatedPercentageDifferenceDisplay)}
              %
            </Text>
          </TextBox>
        </BetweenBox>

        <InfoContainer>
          <BetweenBox>
            <TextMedium>매각희망가</TextMedium>
            <TextMedium>{currentProposedPrice.toLocaleString()}원</TextMedium>
          </BetweenBox>
          <BetweenBox>
            <TextMedium>1조각</TextMedium>
            <TextMedium>{proposedUnitPrice.toLocaleString()}원</TextMedium>
          </BetweenBox>
          <Border />
          <BetweenBox>
            <TextMedium>내가 구매한 금액</TextMedium>
            <TextMedium>{ownedAmount.toLocaleString()}원</TextMedium>
          </BetweenBox>
          <BetweenBox style={{ alignItems: 'flex-start' }}>
            <TextColBox>
              <TextMedium>제안가 판매시 정산 금액</TextMedium>
              <TextSmall style={{ color: '#A6A6A6', textAlign: 'left' }}>
                (매각 수수료 2% 제외)
              </TextSmall>
            </TextColBox>

            <TextBox>
              <TextMedium
                color={settlementAmountPercent < 0 ? '#213E85' : '#FF8A22'}
              >
                {numberWithPlusOrMinusSign(
                  settlementAmountPercent.toFixed(2),
                ) ?? '0'}
                %
              </TextMedium>
              <TextMedium>
                {expectedSalesProceeds.toLocaleString()}원
              </TextMedium>
            </TextBox>
          </BetweenBox>
        </InfoContainer>

        <InfoContainer gap={10}>
          <BetweenBox>
            <TextBox>
              <TextMedium>판매 플랫폼 평균 수수료율</TextMedium>
              <IconInfo
                style={{ cursor: 'pointer' }}
                onClick={() => controlInfoModal()}
              />
            </TextBox>
            <TextMedium>약 {itemPreFee ?? 5}%</TextMedium>
          </BetweenBox>
          <TextSmall
            style={{ cursor: 'pointer' }}
            onClick={() => controlFeeInfoModal()}
          >
            {'플랫폼 수수료 자세히 보기 >'}
          </TextSmall>
          <BetweenBox>
            <TextMedium>물품 판매 예상 등록가</TextMedium>
            <TextMedium>
              {proposedPriceWithPlatformFee.toLocaleString()}원
            </TextMedium>
          </BetweenBox>
        </InfoContainer>
      </Container>

      <CustomModal
        modalOpen={isOpenInfo}
        onRequestClose={() => controlInfoModal()}
      >
        <ModalContainer>
          <ModalTitle>판매 플랫폼 수수료란?</ModalTitle>
          <ModalText>
            외부 플랫폼에 등록시 매각희망가에 플랫폼별 수수료가 추가되어
            등록됩니다.
          </ModalText>
          <ModalButton onClick={() => controlInfoModal()}>확인</ModalButton>
        </ModalContainer>
      </CustomModal>

      <FeeInfoModal
        modalOpen={isOpenFeeInfo}
        onRequestClose={() => controlFeeInfoModal()}
      />
    </>
  );
}

const ModalButton = styled.button`
  width: 100%;
  background-color: #ff8a22;
  padding: 12px 0;
  border-radius: 4px;
  color: var(--white-w-1, #fff);
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ModalText = styled.p`
  color: var(--balck-b-1, #1d1d1d);
  font-family: SUIT;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ModalTitle = styled.h1`
  color: var(--balck-b-1, #1d1d1d);
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TextColBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextSmall = styled.p`
  text-align: right;
  color: #fe8824;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.36px;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin: -4px 0;
`;

const TextMedium = styled.p<{ color?: string }>`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => props.color ?? '#000'};
`;

const InfoContainer = styled.div<{ gap?: number }>`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '20px')};
`;

const Text = styled.p<{ color?: string }>`
  font-family: SUIT;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: ${(props) => props.color ?? '#000'};
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Title = styled.h1`
  font-family: SUIT;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
