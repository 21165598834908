import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { STALE_ONE_MINIUTES } from '../Queries/QueriesConst';
import { VoteTypes, VoteUserTypes } from '../types/AppTypes';
import {
  getVoteItemByProposalIdFetcher,
  getVoteItemDataFetcher,
  getVoteListFetcher,
  getVoteUserDataFetcher,
  postVoteUserFetcher,
  getLiveProposalVoteFetcher,
  checkExistingUserVoteFetchers,
  getDisposalInProgressItemFetcher,
  getVoteSessionAuth,
} from './VoteFetchers';

const getVoteList = (
  voteId?: string,
  options?: UseQueryOptions<
    VoteTypes[],
    AxiosError,
    VoteTypes[],
    ['getVoteList', string | undefined]
  >,
) =>
  useQuery(['getVoteList', voteId], () => getVoteListFetcher(voteId), {
    ...options,
  });

const getVoteUserData = (
  voteId: string,
  options?: UseQueryOptions<
    VoteUserTypes,
    AxiosError,
    VoteUserTypes,
    ['getVoteUserData', string]
  >,
) =>
  useQuery(['getVoteUserData', voteId], () => getVoteUserDataFetcher(voteId), {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });

const postVoteUser = (voteId: number) => {
  const queryClient = useQueryClient();

  return useMutation(['postVoteUser', voteId], postVoteUserFetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVoteList');
      queryClient.invalidateQueries('getVoteUserData');
      queryClient.invalidateQueries('checkExistingUserVote');
    },
  });
};

const checkExistingUserVote = (proposalId: number) =>
  useQuery(['checkExistingUserVote', proposalId], () =>
    checkExistingUserVoteFetchers(proposalId),
  );

// TODO: deprecated
const getVoteItemData = (tradeItemId: number) =>
  useQuery(['getVoteUserData', tradeItemId], () =>
    getVoteItemDataFetcher(tradeItemId),
  );

const getLiveProposalVote = (tradeItemId: number) =>
  useQuery(['getLiveProposalVote', tradeItemId], () =>
    getLiveProposalVoteFetcher(tradeItemId),
  );

const getVoteItemByProposalId = (proposalId: number, options = {}) =>
  useQuery({
    queryKey: ['getVoteItemByProposalId', proposalId],
    queryFn: () => getVoteItemByProposalIdFetcher(proposalId),
    ...options,
  });

const getDisposalInProgressItems = () =>
  useQuery(['getLiveProposalVote'], () => getDisposalInProgressItemFetcher());

const useGetVoteSessionAuth = (uuid: string) =>
  useQuery(['getVoteSessionAuth', uuid], () => getVoteSessionAuth(uuid));

export {
  getVoteList,
  getVoteUserData,
  postVoteUser,
  getVoteItemData,
  getLiveProposalVote,
  checkExistingUserVote,
  getVoteItemByProposalId,
  getDisposalInProgressItems,
  useGetVoteSessionAuth,
};
