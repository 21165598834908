import styled from 'styled-components';

export default function PriceVoteNotices() {
  return (
    <Wrapper>
      <Container>
        <h1>유의사항</h1>
        <ul>
          <li>
            <Built />
            투표 시작까지는 최대 2 영업일이 소요될 수 있습니다.
          </li>
          <li>
            <Built />
            비현실적인 제안 가격은 투표로 이어지지 않을 수 도 있습니다.
          </li>
          <li>
            <Built />
            투표 후 제안가 확정 시 7일간 다른 제안은 불가합니다.
          </li>
        </ul>
      </Container>
    </Wrapper>
  );
}

const Container = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    color: #363b48;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;

      color: #363b48;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.36px;
    }
  }
`;

const Built = styled.span`
  width: 4px;
  height: 4px;
  background-color: #363b48;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  margin: 40px 0 90px 0;
`;
