export const plusOrMinusSign = (value: number) => (value > 0 ? '+' : '');

export const numberWithPlusOrMinusSign = (value: number | string) => {
  const numberValue =
    typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
  const formattedNumber = numberValue.toLocaleString();
  return numberValue > 0 ? `+${formattedNumber}` : formattedNumber;
};

export const formattedNumberWithPlusOrMinusSign = (value: number | string) =>
  Number(value) >= 0 ? `+${value.toLocaleString()}` : value.toLocaleString();

export const roundToTwoDecimalPlaces = (value: number) => {
  const rounded = Number(value.toFixed(2));
  return rounded === -0 ? Math.abs(rounded) : rounded;
};

export const roundToFourDecimalPlaces = (value: number) => {
  const rounded = Number(value.toFixed(4));
  return rounded === -0 ? Math.abs(rounded) : rounded;
};

export const truncateDecimalNumber = (value: number, decimalPlaces: number) => {
  let factor = Math.pow(10, decimalPlaces);
  let truncated = Math.floor(value * factor) / factor;
  return Object.is(truncated, -0) ? 0 : truncated;
};

export const calculatePercentageChange = (
  originalAmount: number,
  newAmount: number,
) => {
  const change = newAmount - originalAmount;
  return (change / originalAmount) * 100;
};
