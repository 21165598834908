import { useEffect, useState } from 'react';
import { format, intervalToDuration, subSeconds } from 'date-fns';
import styled from 'styled-components';
import { IconTime } from '@/assets/icons/vote';

export default function TimeCountdown({
  endDate,
  isScheduled,
}: {
  endDate: Date;
  isScheduled: boolean;
}) {
  const currentTime = new Date();
  let endDateSubSeconds = endDate;
  const [time, setTime] = useState(
    intervalToDuration({ start: currentTime, end: endDateSubSeconds }),
  );

  useEffect(() => {
    !isScheduled &&
      setTimeout(() => {
        endDateSubSeconds = subSeconds(endDateSubSeconds, 1);
        setTime(
          intervalToDuration({ start: currentTime, end: endDateSubSeconds }),
        );
      }, 1000);
  }, [time]);

  return (
    <Container>
      <TextBox isScheduled={isScheduled}>
        <IconTime fill={isScheduled ? '#A6A6A6' : '#FF4B2A'} />
        <Text>
          {isScheduled
            ? `${format(endDate, 'yy년 MM월 dd일 HH시 투표예정')}`
            : `${time.hours}시간 ${time.minutes}분 ${time.seconds}초 후 투표 종료`}
        </Text>
      </TextBox>
    </Container>
  );
}

const Text = styled.span`
  font-weight: 600;
`;

const TextBox = styled.div<{ isScheduled: boolean }>`
  color: ${(props) => (props.isScheduled ? '#A6A6A6' : '#ff4b2a')};
  font-size: 12px;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Container = styled.span`
  border-radius: 4px;
  background-color: #f7f7f7;
`;
